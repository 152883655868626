<template>
  <div id="top" class="flex-shrink flex flex-col w-full relative self-start">
    <BaseLoading v-if="!labels" class="my-12" />
    <div
      v-if="labels"
      class="sticky top-0 left-0 right-0 z-20 bg-white shadow w-full"
    >
      <!-- <div>
        <span class="ml-3 text-gray-500 italic" v-if="labels"
          >({{ labels.length }} labels)</span
        >
      </div> -->
      <div class="flex p-3 items-center justify-between">
        <div class="flex items-center gap-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 cursor-pointer flipper"
            :class="{ flip: !showSidebar }"
            viewBox="0 0 20 20"
            fill="currentColor"
            @click="$store.dispatch('toggleShowSidebar')"
          >
            <path
              fill-rule="evenodd"
              d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
          <div class="cursor-pointer font-bold" @click="newLabel">
            New Label
          </div>
        </div>
        <div class="flex space-x-6 font-bold items-center">
          <button v-if="zoomedOut" @click="selectAll">Select All</button>
          <button v-if="zoomedOut" @click="selectNone">Select None</button>
          <a
            v-if="group && zoomedOut"
            :class="{ 'disabled opacity-20': !hasSelected }"
            :href="downloadSelectedLink"
            target="_blank"
          >
            Download Selected
          </a>
          <a
            v-if="group"
            class="block flex items-center"
            :href="downloadAllLink"
            target="_blank"
          >
            Download All
          </a>
          <button @click="$store.commit('TOGGLE_ZOOM')" class="font-bold">
            <span v-if="zoomedOut">Zoom In</span>
            <span v-else>Zoom Out</span>
          </button>
          <FilterInput
            v-if="labels"
            class="w-72 mx-auto relative"
            placeholder="Filter Labels"
            action="setLabelFilter"
          />
        </div>
      </div>
      <div v-if="group" class="sticky top-0">
        <div class="flex">
          <BaseStatusBar
            v-if="group.numLabelsDraft"
            color="red"
            :count="group.numLabelsDraft"
            :total="group.numLabels"
            :showText="true"
            label="Draft"
          />
          <BaseStatusBar
            v-if="group.numLabelsEditorial"
            color="yellow"
            :count="group.numLabelsEditorial"
            :total="group.numLabels"
            :showText="true"
            label="Editorial"
          />
          <BaseStatusBar
            v-if="group.numLabelsProduction"
            color="green"
            :count="group.numLabelsProduction"
            :total="group.numLabels"
            :showText="true"
            label="Production"
          />
          <BaseStatusBar
            v-if="group.numLabelsArchived"
            color="gray"
            :count="group.numLabelsArchived"
            :total="group.numLabels"
            :showText="true"
            label="Archived"
          />

          <!-- TODO: Hacky force badge colours -->
          <span
            class="hidden bg-red-100 text-red-900 bg-yellow-100 text-yellow-900 bg-green-100 text-green-900 bg-gray-100 text-gray-900"
          />
        </div>
      </div>
    </div>

    <div
      v-if="labels"
      class="py-16 flex flex-col"
      :class="{
        'grid grid-cols-4 w-full gap-y-12': zoomedOut,
        'flex flex-col space-y-16': !zoomedOut,
      }"
    >
      <Label
        v-for="label in labels"
        :key="label._id"
        :label="processLabel(label)"
        :zoomedOut="zoomedOut"
        :class="{
          'transform zoomed-out mx-auto': zoomedOut,
          'mx-auto': !zoomedOut,
        }"
      />
    </div>
    <div
      @click="$store.dispatch('setFilterTerm', '')"
      v-if="$store.state.filterTerm && labels && labels.length === 0"
      class="rounded-lg p-6 bg-white transition-all duration-300 shadow-sm hover:shadow-lg cursor-pointer flex justify-between w-label mx-auto"
    >
      <div>
        Nothing found for
        <span class="italic">{{ this.$store.state.filterTerm }}</span
        >.
      </div>
      <div>Click to reset the filter</div>
    </div>
    <div
      @click="newLabel"
      v-else-if="labels && labels.length === 0"
      class="rounded-lg p-6 bg-white transition-all duration-300 shadow-sm hover:shadow-lg cursor-pointer flex justify-between w-label mx-auto text-center"
    >
      No labels yet! Click to create a new label.
    </div>
  </div>
</template>

<script>
import FilterInput from "@/components/FilterInput";
import Label from "@/components/Label";

export default {
  name: "Group",
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    group() {
      return this.$store.state.currentGroup;
    },
    labels() {
      return this.$store.getters.labels;
    },
    zoomedOut() {
      return this.$store.state.zoomedOut;
    },
    hasSelected() {
      return this.$store.state.selectedLabels.length;
    },
    downloadAllLink() {
      return `${process.env.VUE_APP_API_URL}/export/groups/${this.group.id}`;
    },
    downloadSelectedLink() {
      return `${
        process.env.VUE_APP_API_URL
      }/export/selected/${this.$store.state.selectedLabels.join(",")}`;
    },
    showSidebar() {
      return this.$store.state.showSidebar;
    },
  },
  mounted() {
    this.$store.dispatch("setFilterTerm", "");
    this.fetch();
    window.setTimeout(() => {
      // TODO: This scrollto is a bit hacky
      if (this.$route.hash) {
        this.$nextTick(() => {
          this.$scrollTo("#top", {
            container: "#content",
          });
        });
      }
    }, 1000);
  },
  methods: {
    fetch() {
      this.$store.dispatch("fetchGroup", this.$route.params.groupId);
      this.$store.dispatch("fetchLabels", this.$route.params.groupId);
    },
    selectAll() {
      this.$store.commit(
        "SET_SELECTED_LABELS",
        this.labels.map((x) => x._id)
      );
    },
    selectNone() {
      this.$store.commit("SET_SELECTED_LABELS", []);
    },
    newLabel() {
      this.$store.dispatch("addLabel", {
        group: this.group._id,
        html: "Edit me!",
        text: "Edit me!",
        status: "",
        deleted: false,
      });
    },
    processLabel(label) {
      // Replace header tags clientside on load, label won't be updates
      // in db until it's saved. Extranneous whitespace also removed
      // https://github.com/MuseumofModernArt/moma-labels-frontend/issues/28
      if (label && label.html) {
        label.html = label.html
          .replaceAll("<h1>", "<large>")
          .replaceAll("</h1>", "</large>")
          .replaceAll("<p>\n</p>", "")
          .replaceAll("\n", "");
        return label;
      } else {
        return label;
      }
      // console.log(label);
      // return label;
    },
  },
  watch: {
    $route() {
      this.$store.commit("FETCH_LABELS", null);
      this.fetch();
      this.$store.dispatch("setFilterTerm", "");
    },
    labels() {
      this.$scrollTo("#top", {
        container: "#content",
      });
    },
  },
  components: {
    Label,
    FilterInput,
  },
};
</script>

<style lang="css" scoped>
.flipper {
  transition: transform 0.5s;
  transform-style: preserve-3d;
}
.flip {
  transform: rotateY(180deg);
}
</style>
